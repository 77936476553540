function mezcla() {

    const flavors = document.querySelectorAll('.boxMezcla--right__content--flavor')

    flavors && flavors?.forEach(flavor => {
        switch (flavor.innerHTML) {
            case 'Papas':
                const targetPapas = document.querySelector('.papas');
                    targetPapas.classList.add('show')
                break;
            case 'Chicharon':
                const targetchicharon = document.querySelector('.chicharron');
                    targetchicharon.classList.add('show')
                break;
            case 'Chicharron':
                const targetchicharron = document.querySelector('.chicharron');
                    targetchicharron.classList.add('show')
                break;
            case 'Natuchips':
                const targetnatuchips = document.querySelector('.natuchips');
                    targetnatuchips.classList.add('show')
                break;
            case 'Cheesetris':
                const targetcheesetris = document.querySelector('.cheesetris');
                    targetcheesetris.classList.add('show')
                break;
            case 'Cheetos':
                const targetcheetos = document.querySelector('.cheetos');
                    targetcheetos.classList.add('show')
                break;
            case 'Doritos':
                const targetdoritos = document.querySelector('.doritos');
                    targetdoritos.classList.add('show')
                break;
            case 'Onduladas':
                const targetonduladas = document.querySelector('.onduladas');
                    targetonduladas.classList.add('show')
                break;
            case 'Tocinetas':
                const targettocineta = document.querySelector('.tocineta');
                    targettocineta.classList.add('show')
                break;
            case 'Arepitas':
                const targetarepitas = document.querySelector('.arepitas');
                    targetarepitas.classList.add('show')
                break;
            default:
                console.log();
        }
    })
    
}
export { mezcla };
