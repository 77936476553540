
function animations(){
    var scene = document.querySelector('.iconsuserArea');
    var scene1 = document.querySelector('.iconsRegister');
    var scene2 = document.querySelector('.iconsLogin');
    var scene3 = document.querySelector('.iconsForward');
    var scene4 = document.querySelector('.iconsconfPass');
    var scene5 = document.querySelector('.iconsAlbum');
    var scene6 = document.querySelector('.iconsAwards');
    var scene7 = document.querySelector('.iconlGame');
    scene && new Parallax(scene);
    scene1 && new Parallax(scene1);
    scene2 && new Parallax(scene2);
    scene3 && new Parallax(scene3);
    scene4 && new Parallax(scene4);
    scene5 && new Parallax(scene5);
    scene6 && new Parallax(scene6);
    scene7 && new Parallax(scene7);
}

export {
    animations
}