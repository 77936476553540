import {
    validateForm
} from './components/validationForm'
import {
    animations
} from './components/animations'
import {
    popups
} from './components/popup'
import {
    album
} from './pages/album'
import {
    ranking
} from './pages/ranking'
import {
    awardsRedemption
} from './pages/awardsRedemption'
import {
    mezcla
} from './pages/mezcla'
import {
    home
} from './pages/home'
import {
    sku, quitLink
} from './pages/sku'
// import {
//     policieAndCookie
// } from './pages/policieAndCookie.js'

import * as AOS from 'aos';
import 'aos/dist/aos.css';

import LocomotiveScroll from 'locomotive-scroll';

window.onload = () => {
}


window.addEventListener('DOMContentLoaded', () => {
    let preloaded = document.querySelector('.preloader');
    preloaded.classList.add('hidden');
    let noScroll = document.querySelector('.min-h-screen');
    noScroll?.classList.remove('no-scroll');

    AOS.init({
        once: true,
        duration: 2000
    });


    if (document.querySelector('.contentparallax')) {

        const scroller = new LocomotiveScroll({
            el: document.querySelector('[data-scroll-container]'),
            smooth: true
        });
    }

    //MENU MOBILE
    const menuMobile = document.querySelector('.menuMobile')
    menuMobile && menuMobile.addEventListener('click', e => {
        e.preventDefault()
        let menuItems = document.querySelector('.header');
        menuItems.classList.toggle('active')
        menuMobile.classList.toggle('active')
        document.querySelector('main.container') ? document.querySelector('body').classList.toggle('removeHeight') : ''
    })

    policieAndCookie()
    home()
    sku()
    quitLink()
    validateForm()
    animations()
    popups()
    album()
    ranking()
    awardsRedemption()
    mezcla()
    redencionSubmit()

    function policieAndCookie() {
        let policieAndCookie = localStorage.getItem("policieAndCookie");
        let modaliniciahome = document.querySelector(".modaliniciahome");

        if (!policieAndCookie) {
            modaliniciahome.classList.add("activePopup");
        }

        let btnSi = document.querySelector(".acepted");
        let btnNo = document.querySelector(".btnInicioNo");

        btnSi.addEventListener("click", (Si) => {
            localStorage.setItem("policieAndCookie", true);
            modaliniciahome.classList.remove("activePopup");
            document.querySelector('.modal.modalInformation')?.classList.add('activePopup')
        });

        btnNo.addEventListener("click", (No) => {
            modaliniciahome.classList.remove("activePopup");
            window.history.back();
        });
    }

    function redencionSubmit() {
        const btnSubmit = document.querySelector('.formG__redimir .formG__content--btn button')
        btnSubmit && btnSubmit.addEventListener('click', e => {
            e.preventDefault()
            let arrInputText = [...document.querySelectorAll('.formG__content--input.text input')]
            let arrInputCheck = [...document.querySelectorAll('.formG__content--input.check input')]
            let arrSelect = [...document.querySelectorAll('.formG__content--input.select select')]
            const inputChe = arrInputCheck.map( e => {
                if (!e.checked) {
                    return false;
                } else {
                    return true;
                }
            })
            const inputTe = arrInputText.map( e => {
                if (e.value === "") {
                    return false;
                } else {
                    return true;
                }
            })
            const select = arrSelect.map( x => {
                if (x.value === "") {
                    return false;
                }  else {
                    return true;
                }

            })
            if(select.includes(false) || inputChe.includes(false) || inputTe.includes(false)) {
                document.querySelector('#formRegister').submit()
            } else {
                const textWallet = document.querySelector('.containerRedimir #wallet_address').value;
                document.querySelector('.modalVideoWallet .code').textContent = textWallet;
                document.querySelector('.modal.modalVideoWallet').classList.add('activePopup')
                document.querySelector('.btnInicioSi').addEventListener('click', () => document.querySelector('#formRegister').submit())
            }
        })
    }

    if (document.querySelector('.boxAwards__content--carousel')) {
        const swiper = new Swiper('.boxAwards__content--carousel', {
            slidesPerView: 1,
            spaceBetween: 30,
            centeredSlides: true,
            loop: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            breakpoints: {
                310: {
                    slidesPerView: 1,
                    spaceBetween: 50,
                },
                500: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                },
                991: {
                    slidesPerView: 4,
                    spaceBetween: 30
                },
                1024: {
                    slidesPerView: 6,
                    spaceBetween: 10,
                }
            }
        });
    }

    if (document.querySelector('.carouselAwards__content--carouseltops')) {
        const swiper = new Swiper('.carouselAwards__content--carouseltops', {
            slidesPerView: 1,
            spaceBetween: 30,
            /*centeredSlides: true,*/
            loop: false,

            breakpoints: {
                310: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                },
                900: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                },
                1325: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                },
                1625: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                }
            }
        });
    }

    if (document.querySelector('.carouselNfts')) {
        const swiperNft = new Swiper('.carouselNfts', {
            slidesPerView: 1,
            spaceBetween: 30,
            centeredSlides: true,
            loop: true,
            // autoplay: {
            //     delay: 2500,
            //     disableOnInteraction: false,
            // },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                    pagination: {
                        dynamicBullets: true,
                        dynamicMainBullets: 1,
                    }
                },
                620: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 30
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
                1640: {
                    slidesPerView: 6,
                    spaceBetween: 30,
                }
            }
        });
    }

    if (document.querySelector('.carouselAwards__content--carousel')) {
        const swiperAw = new Swiper('.carouselAwards__content--carousel', {
            slidesPerView: 1,
            // spaceBetween: 30,
            // centeredSlides: true,
            loop: true,

            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            breakpoints: {
                480: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                620: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 20
                },
                1024: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 5,
                    spaceBetween: 30,
                }
            }
        });
    }

    if (document.querySelector('.boxcodesSnacks')) {
        const swiperSnacks = new Swiper(".boxcodesSnacks", {
            slidesPerView: 1,
            spaceBetween: 30,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 30
                },
                1024: {
                    slidesPerView: 5,
                    spaceBetween: 30,
                }
            }
        });
    }

    if (document.querySelector('.boxBannerC')) {
        const swiperHome = new Swiper('.boxBannerC', {
            // Default parameters
            slidesPerView: 1,
            // spaceBetween: 10,
            // loop: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            on: {
                slideChangeTransitionStart: function() {
                    var paqnatural = document.getElementById("paquete-natural");
                    var paqbbq = document.getElementById("paquete-bbq");
                    var paqlimon = document.getElementById("paquete-limon");
                    var paqkfh = document.getElementById("paquete-kfh");
                    var paqmix = document.getElementById("paquete-mix");
                    paqnatural.style.display = 'none';
                    paqnatural.classList.remove('aos-init');
                    paqnatural.classList.remove('aos-animate');
                    //--
                    paqbbq.style.display = 'none';
                    paqbbq.classList.remove('aos-init');
                    paqbbq.classList.remove('aos-animate');
                    //--
                    paqlimon.style.display = 'none';
                    paqlimon.classList.remove('aos-init');
                    paqlimon.classList.remove('aos-animate');
                    //--
                    paqkfh.style.display = 'none';
                    paqkfh.classList.remove('aos-init');
                    paqkfh.classList.remove('aos-animate');
                    //--
                    paqmix.style.display = 'none';
                    paqmix.classList.remove('aos-init');
                    paqmix.classList.remove('aos-animate');
                    //--
                },
                slideChangeTransitionEnd: function() {
                    var paqnatural = document.getElementById("paquete-natural");
                    var paqbbq = document.getElementById("paquete-bbq");
                    var paqlimon = document.getElementById("paquete-limon");
                    var paqkfh = document.getElementById("paquete-kfh");
                    var paqmix = document.getElementById("paquete-mix");
                    paqnatural.style.display = '';
                    paqbbq.style.display = '';
                    paqlimon.style.display = '';
                    paqkfh.style.display = '';
                    paqmix.style.display = '';
                    AOS.init();
                },
            }
        })
    }

    /*slider de productos mezcla*/

    if (document.querySelector('.slideflavors')) {
        const swiperflavors = new Swiper('.slideflavors', {
            // Default parameters
            slidesPerView: 1,
            spaceBetween: 10,
            loop: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        })
    }

    /*DRAG AND DROP -- funciones*/

    var dragSrcEl
    var ingList = [];


    function handleDragStart(e) {
        // this.style.opacity = '0.4';
        if (this.classList.contains('pqtpapas')) {
            // console.log('son papas')
            dragSrcEl = 'papas';
        } else if (this.classList.contains('pqtchicharron')) {
            // console.log('es chicharron')
            dragSrcEl = 'chicharron';
        } else if (this.classList.contains('pqtnatuchips')) {
            // console.log('son natuchips')
            dragSrcEl = 'natuchips';
        } else if (this.classList.contains('pqtmanimoto')) {
            // console.log('son manimoto')
            dragSrcEl = 'manimoto';
        } else if (this.classList.contains('pqtcheesetris')) {
            // console.log('son cheesetris')
            dragSrcEl = 'cheesetris';
        } else if (this.classList.contains('pqtcheetos')) {
            // console.log('son cheetos')
            dragSrcEl = 'cheetos';
        } else if (this.classList.contains('pqtdoritos')) {
            // console.log('son doritos')
            dragSrcEl = 'doritos';
        } else if (this.classList.contains('pqtchokis')) {
            // console.log('son chokis')
            dragSrcEl = 'chokis';
        } else if (this.classList.contains('pqtcrispetas')) {
            // console.log('es crispetas')
            dragSrcEl = 'crispetas';
        } else if (this.classList.contains('pqtchoclitos')) {
            // console.log('son choclitos')
            dragSrcEl = 'choclitos';
        } else {
            console.log('noahynipapa')
        }
    }

    function handleDragEnd(e) {
        this.style.opacity = '1';
    }

    function handleDragOver(e) {
        if (e.preventDefault) {
            e.preventDefault();
        }
        return false;
    }

    function handleDragEnter(e) {
        if (dragSrcEl == 'papas') {
            let papitas = document.querySelectorAll('.papas');
            papitas.forEach(function(item) {
                item.classList.add('show');
            });
        } else if (dragSrcEl == 'chicharron') {
            let chicharron = document.querySelectorAll('.chicharron');
            chicharron.forEach(function(item) {
                item.classList.add('show');
            });
        } else if (dragSrcEl == 'natuchips') {
            let natuchips = document.querySelectorAll('.natuchips');
            natuchips.forEach(function(item) {
                item.classList.add('show');
            });
        } else if (dragSrcEl == 'manimoto') {
            let manimoto = document.querySelectorAll('.manimoto');
            manimoto.forEach(function(item) {
                item.classList.add('show');
            });
        } else if (dragSrcEl == 'cheesetris') {
            let cheesetris = document.querySelectorAll('.cheesetris');
            cheesetris.forEach(function(item) {
                item.classList.add('show');
            });
        } else if (dragSrcEl == 'cheetos') {
            let cheetos = document.querySelectorAll('.cheetos');
            cheetos.forEach(function(item) {
                item.classList.add('show');
            });
        } else if (dragSrcEl == 'doritos') {
            let doritos = document.querySelectorAll('.doritos');
            doritos.forEach(function(item) {
                item.classList.add('show');
            });
        } else if (dragSrcEl == 'chokis') {
            let chokis = document.querySelectorAll('.chokis');
            chokis.forEach(function(item) {
                item.classList.add('show');
            });
        } else if (dragSrcEl == 'crispetas') {
            let crispetas = document.querySelectorAll('.crispetas');
            crispetas.forEach(function(item) {
                item.classList.add('show');
            });
        } else if (dragSrcEl == 'choclitos') {
            let choclitos = document.querySelectorAll('.choclitos');
            choclitos.forEach(function(item) {
                item.classList.add('show');
            });
        }

    }

    function handleDragLeave(e) {
        if (dragSrcEl == 'papas') {
            let papitas = document.querySelectorAll('.papas');
            papitas.forEach(function(item) {
                item.classList.remove('show');
            });
        } else if (dragSrcEl == 'chicharron') {
            let chicharron = document.querySelectorAll('.chicharron');
            chicharron.forEach(function(item) {
                item.classList.remove('show');
            });
        } else if (dragSrcEl == 'natuchips') {
            let natuchips = document.querySelectorAll('.natuchips');
            natuchips.forEach(function(item) {
                item.classList.remove('show');
            });
        } else if (dragSrcEl == 'manimoto') {
            let manimoto = document.querySelectorAll('.manimoto');
            manimoto.forEach(function(item) {
                item.classList.remove('show');
            });
        } else if (dragSrcEl == 'cheesetris') {
            let cheesetris = document.querySelectorAll('.cheesetris');
            cheesetris.forEach(function(item) {
                item.classList.remove('show');
            });
        } else if (dragSrcEl == 'cheetos') {
            let cheetos = document.querySelectorAll('.cheetos');
            cheetos.forEach(function(item) {
                item.classList.remove('show');
            });
        } else if (dragSrcEl == 'doritos') {
            let doritos = document.querySelectorAll('.doritos');
            doritos.forEach(function(item) {
                item.classList.remove('show');
            });
        } else if (dragSrcEl == 'chokis') {
            let chokis = document.querySelectorAll('.chokis');
            chokis.forEach(function(item) {
                item.classList.remove('show');
            });
        } else if (dragSrcEl == 'crispetas') {
            let crispetas = document.querySelectorAll('.crispetas');
            crispetas.forEach(function(item) {
                item.classList.remove('show');
            });
        } else if (dragSrcEl == 'choclitos') {
            let choclitos = document.querySelectorAll('.choclitos');
            choclitos.forEach(function(item) {
                item.classList.remove('show');
            });
        }
    }

    function handleDrop(e) {
        e.stopPropagation(); // stops the browser from redirecting.
        if (dragSrcEl == 'papas') {
            const elementSelected = document.querySelector(".pqtpapas");
            const classActSelected = elementSelected.classList;
            classActSelected.add("ingSelected");
            elementSelected.setAttribute("ondragstart", "return false");
            let papitas = document.querySelectorAll('.papas');
            ingList.push('Papas');
            papitas.forEach(function(item) {
                item.classList.add('show');
            });
        } else if (dragSrcEl == 'chicharron') {
            const elementSelected = document.querySelector(".pqtchicharron");
            const classActSelected = elementSelected.classList;
            classActSelected.add("ingSelected");
            elementSelected.setAttribute("ondragstart", "return false");
            let chicharron = document.querySelectorAll('.chicharron');
            ingList.push('Chicharron');
            chicharron.forEach(function(itemw) {
                itemw.classList.add('show');
            });
        } else if (dragSrcEl == 'natuchips') {
            const elementSelected = document.querySelector(".pqtnatuchips");
            const classActSelected = elementSelected.classList;
            classActSelected.add("ingSelected");
            elementSelected.setAttribute("ondragstart", "return false");
            let natuchips = document.querySelectorAll('.natuchips');
            ingList.push('Natuchips');
            natuchips.forEach(function(itemw) {
                itemw.classList.add('show');
            });
        } else if (dragSrcEl == 'manimoto') {
            const elementSelected = document.querySelector(".pqtmanimoto");
            const classActSelected = elementSelected.classList;
            classActSelected.add("ingSelected");
            elementSelected.setAttribute("ondragstart", "return false");
            let manimoto = document.querySelectorAll('.manimoto');
            ingList.push('Mani-Moto');
            manimoto.forEach(function(itemw) {
                itemw.classList.add('show');
            });
        } else if (dragSrcEl == 'cheesetris') {
            const elementSelected = document.querySelector(".pqtcheesetris");
            const classActSelected = elementSelected.classList;
            classActSelected.add("ingSelected");
            elementSelected.setAttribute("ondragstart", "return false");
            let cheesetris = document.querySelectorAll('.cheesetris');
            ingList.push('Cheese-Tris');
            cheesetris.forEach(function(itemw) {
                itemw.classList.add('show');
            });
        } else if (dragSrcEl == 'cheetos') {
            const elementSelected = document.querySelector(".pqtcheetos");
            const classActSelected = elementSelected.classList;
            classActSelected.add("ingSelected");
            elementSelected.setAttribute("ondragstart", "return false");
            let cheetos = document.querySelectorAll('.cheetos');
            ingList.push('Cheetos');
            cheetos.forEach(function(itemw) {
                itemw.classList.add('show');
            });
        } else if (dragSrcEl == 'doritos') {
            const elementSelected = document.querySelector(".pqtdoritos");
            const classActSelected = elementSelected.classList;
            classActSelected.add("ingSelected");
            elementSelected.setAttribute("ondragstart", "return false");
            let doritos = document.querySelectorAll('.doritos');
            ingList.push('Doritos');
            doritos.forEach(function(itemw) {
                itemw.classList.add('show');
            });
        } else if (dragSrcEl == 'chokis') {
            const elementSelected = document.querySelector(".pqtchokis");
            const classActSelected = elementSelected.classList;
            classActSelected.add("ingSelected");
            elementSelected.setAttribute("ondragstart", "return false");
            let chokis = document.querySelectorAll('.chokis');
            ingList.push('chokis');
            chokis.forEach(function(itemw) {
                itemw.classList.add('show');
            });
        } else if (dragSrcEl == 'crispetas') {
            const elementSelected = document.querySelector(".pqtcrispetas");
            const classActSelected = elementSelected.classList;
            classActSelected.add("ingSelected");
            elementSelected.setAttribute("ondragstart", "return false");
            let crispetas = document.querySelectorAll('.crispetas');
            ingList.push('crispetas');
            crispetas.forEach(function(itemw) {
                itemw.classList.add('show');
            });
        } else if (dragSrcEl == 'choclitos') {
            const elementSelected = document.querySelector(".pqtchoclitos");
            const classActSelected = elementSelected.classList;
            classActSelected.add("ingSelected");
            elementSelected.setAttribute("ondragstart", "return false");
            let choclitos = document.querySelectorAll('.choclitos');
            ingList.push('choclitos');
            choclitos.forEach(function(itemw) {
                itemw.classList.add('show');
            });
        }

        if (ingList.length == 4) {
            //alert ("ya son 4");

            let quitaatributos = document.querySelectorAll('.itmoves');
            quitaatributos.forEach(function(item) {
                //item.classList.add('ingSelected');
                item.setAttribute("ondragstart", "return false");
            });
            let modalsiguiente = document.querySelectorAll('.modalmezclacomplete');
            modalsiguiente.forEach(function(item) {
                item.classList.add('show');
            });

            let btnsiguiente = document.querySelectorAll('.nextmezcla01');
            btnsiguiente.forEach(function(item) {
                item.classList.add('show');
            });
            let btnClean = document.querySelector('.boxMezcla--left--btn');
            btnClean.classList.add('activeBtn')
            // const ingItems = document.querySelectorAll(".itmoves");
            // ingItems.setAttribute("class", "ingSelected");
            // ingItems.setAttribute("ondragstart", "return false");
        }

        const blend = document.querySelector('#blend');
        blend.value = ingList;
        const ingObj = document.querySelector(".bolsa");
        ingObj.setAttribute("data-mezcla", ingList);

        return false;
    }

    let items = document.querySelectorAll('.itmoves');
    items.forEach(function(item) {
        item.addEventListener('dragstart', handleDragStart);
        item.addEventListener('dragend', handleDragEnd);
    });

    let itemputin = document.querySelectorAll('.bolsa .putin');
    itemputin.forEach(function(item) {
        item.addEventListener('dragover', handleDragOver);
        item.addEventListener('dragenter', handleDragEnter);
        item.addEventListener('dragleave', handleDragLeave);
        item.addEventListener('dragend', handleDragEnd);
        item.addEventListener('drop', handleDrop);
    });

    let btnNextMezcla01 = document.querySelector('.nextmezcla01');
    btnNextMezcla01 && btnNextMezcla01.addEventListener('click', function(e) {
        document.querySelector('#formSaveBlend').submit();
    })

    /**----------------------- */

    /**FUNCIONES MOBILE */
    function mobileselect(e){

        if (ingList.length < 6) {
            if(!(this.classList.contains('ingSelected'))){

                if (this.classList.contains('pqtpapasmob')) {
                    console.log('son papas')
                    dragSrcEl = 'papas';
                    this.classList.add('ingSelected')
                    let papitas = document.querySelectorAll('.papas');
                    ingList.push('Papas');
                    papitas.forEach(function(item) {
                        item.classList.add('show');
                    });
                } else if (this.classList.contains('pqtchicharronmob')) {
                    console.log('es chicharron')
                    dragSrcEl = 'chicharron';
                    this.classList.add('ingSelected')
                    let chicharrons = document.querySelectorAll('.chicharron');
                    ingList.push('Chicharron');
                    chicharrons.forEach(function(item) {
                        item.classList.add('show');
                    });
                } else if (this.classList.contains('pqtnatuchipsmob')) {
                    console.log('son natuchips')
                    dragSrcEl = 'natuchips';
                    this.classList.add('ingSelected')
                    let natuchipss = document.querySelectorAll('.natuchips');
                    ingList.push('Natuchips');
                    natuchipss.forEach(function(item) {
                        item.classList.add('show');
                    });
                } else if (this.classList.contains('pqtmanimotomob')) {
                    console.log('son manimoto')
                    dragSrcEl = 'manimoto';
                    this.classList.add('ingSelected')
                    let manimotos = document.querySelectorAll('.manimoto');
                    ingList.push('manimoto');
                    manimotos.forEach(function(item) {
                        item.classList.add('show');
                    });
                } else if (this.classList.contains('pqtcheesetrismob')) {
                    console.log('son cheesetris')
                    dragSrcEl = 'cheesetris';
                    this.classList.add('ingSelected')
                    let cheesetriss = document.querySelectorAll('.cheesetris');
                    ingList.push('Cheesetris');
                    cheesetriss.forEach(function(item) {
                        item.classList.add('show');
                    });
                } else if (this.classList.contains('pqtcheetosmob')) {
                    console.log('son cheetos')
                    dragSrcEl = 'cheetos';
                    this.classList.add('ingSelected')
                    let cheetoss = document.querySelectorAll('.cheetos');
                    ingList.push('Cheetos');
                    cheetoss.forEach(function(item) {
                        item.classList.add('show');
                    });
                } else if (this.classList.contains('pqtdoritosmob')) {
                    console.log('son doritos')
                    dragSrcEl = 'doritos';
                    this.classList.add('ingSelected')
                    let doritoss = document.querySelectorAll('.doritos');
                    ingList.push('Doritos');
                    doritoss.forEach(function(item) {
                        item.classList.add('show');
                    });
                } else if (this.classList.contains('pqtchokismob')) {
                    console.log('son chokis')
                    dragSrcEl = 'chokis';
                    this.classList.add('ingSelected')
                    let chokiss = document.querySelectorAll('.chokis');
                    ingList.push('chokis');
                    chokiss.forEach(function(item) {
                        item.classList.add('show');
                    });
                } else if (this.classList.contains('pqtcrispetasmob')) {
                    console.log('es crispetas')
                    dragSrcEl = 'crispetas';
                    this.classList.add('ingSelected')
                    let crispetass = document.querySelectorAll('.crispetas');
                    ingList.push('crispetas');
                    crispetass.forEach(function(item) {
                        item.classList.add('show');
                    });
                } else if (this.classList.contains('pqtchoclitosmob')) {
                    console.log('son choclitos')
                    dragSrcEl = 'choclitos';
                    this.classList.add('ingSelected')
                    let choclitoss = document.querySelectorAll('.choclitos');
                    ingList.push('choclitos');
                    choclitoss.forEach(function(item) {
                        item.classList.add('show');
                    });
                } else {
                    console.log('noahynipapa')
                }

                if (ingList.length == 4) {
                    //alert ("ya son 4");

                    console.log(ingList)
                    let quitaatributos = document.querySelectorAll('.itclickr');
                    quitaatributos.forEach(function(item) {
                        item.classList.remove('itclickr');
                    });

                    let modalsiguiente = document.querySelectorAll('.modalmezclacomplete');
                    modalsiguiente.forEach(function(item) {
                        item.classList.add('show');
                    });

                    let btnsiguiente = document.querySelectorAll('.nextmezcla01');
                    btnsiguiente.forEach(function(item) {
                        item.classList.add('show');
                    });
                    let btnClean = document.querySelector('.boxMezcla--left--btn');
                    btnClean.classList.add('activeBtn')


                }
                const ingObj = document.querySelector(".bolsa");
                const blend = document.querySelector('#blend');
                blend.value = ingList;
                ingObj.setAttribute("data-mezcla", ingList);

                return false;

            }
        }
    }

    let clickitems = document.querySelectorAll('.itclickr');
    clickitems.forEach(function(item) {
        item.addEventListener('click', mobileselect);
    });

    const cleanDrag = document.querySelector('.boxMezcla--left--btn')

    cleanDrag?.addEventListener('click', e => {
        e.preventDefault()
        const bolsa = document.querySelector('.bolsa'),
            allIcons = document.querySelectorAll('.iconBolsa'),
            allSelects = document.querySelectorAll('.boxMezcla--right--flavors--single')

        document.querySelector('.modalmezclacomplete').classList.remove('show');
        bolsa.dataset.mezcla = "['empty']";

        allIcons?.forEach(function(allIcon){
            if(allIcon.classList.contains('show')){
                allIcon.classList.remove('show')
            }
        })
        allSelects?.forEach(function(allSelect){
            if(allSelect.classList.contains('ingSelected')){
                allSelect.classList.remove('ingSelected')
            }
            allSelect.setAttribute('ondragstart','return true')
        })

        ingList = []

        cleanDrag.classList.remove('activeBtn')
        document.querySelector('.nextmezcla01').classList.remove('show')
    })


    /*Function exit ig & fb Nav */

    // const btnExitNav = document.querySelector('.btn_exit_nav_mobile');

    // btnExitNav?.addEventListener('click', e => {
    //     e.preventDefault()
    //     if(navigator.userAgent.includes("FBAV") || navigator.userAgent.includes("Instagram")){
    //         let link = window.location.href.replace(/^https:\/\// && /detodito/,'' )
    //         link = 'detoditocolombia.com.co/'
    //         console.log(navigator.userAgent);
    //         btnExitNav.href = `intent://${link}#Intent;scheme=https;action=android.intent.action.VIEW;end;`
    //         xhr.pause()
    //         xhr.stop()
    //     }else{
    //         window.location.href = btnExitNav.href;
    //     }
    // })
})
