function sku() {
    gsap.registerPlugin(ScrollTrigger);
    let getRatio = (el) =>
        window.innerHeight / (window.innerHeight + el.offsetHeight);

    gsap.utils.toArray(".containerSku__single").forEach((section, i) => {
        section.bg = section.querySelector(".containerSku__single--bk");

        // the first image (i === 0) should be handled differently because it should start at the very top.
        // use function-based values in order to keep things responsive
        gsap.fromTo(
            section.bg,
            {
                backgroundPosition: () =>
                    i
                        ? `50% ${-window.innerHeight * getRatio(section)}px`
                        : "50% 0px",
            },
            {
                backgroundPosition: () =>
                    `50% ${window.innerHeight * (1 - getRatio(section))}px`,
                ease: "none",
                scrollTrigger: {
                    trigger: section,
                    start: () => (i ? "top bottom" : "top top"),
                    end: "bottom top",
                    scrub: 0,
                    invalidateOnRefresh: true, // to make it responsive
                },
            }
        );
    });
}

function quitLink() {
    let bkTextContents = document.querySelectorAll(".bktextcontent .quitPay");
    bkTextContents.forEach((item) => {
        item.addEventListener("click", (e) => {
            e.preventDefault();

            let modalrappi = document.querySelector(".modalrappi");
            let botonrappi = document.querySelector(".botonrappi");

            modalrappi.classList.add('activePopup');
            botonrappi.href = e.target.href;
        });
    });
}

export { sku, quitLink };
