//POPUP LOGOUT

function popups(){
    const activePopups = document.querySelectorAll(".popupLogout"),
        modalPopup = document.querySelector(".modalLogout"),
        modalMural = document.querySelector(".boxAR__content--left--btn"),
        modalCommercial = document.querySelector(".boxPromos__content--commercial--boxImg"),
        videoModal = document.querySelector(".videoModal"),
        btnClose = modalPopup && modalPopup.querySelector(".btnClosePopup"),
        closePopups = document.querySelectorAll(".closePopup"),
        activeVideos = document.querySelectorAll(".modalVideo"),
        popUpsClose = [...document.querySelectorAll('.popUpClose')];

    // activePopup && activePopup.addEventListener("click", (e) => {
    //     e.preventDefault();
    //     modalPopup.classList.add("activePopup");
    // });

    activePopups.forEach(activePopup => {
        activePopup && activePopup.addEventListener("click", (e) => {
            e.preventDefault();
            modalPopup.classList.add("activePopup");
        });
    })

    modalMural && modalMural.addEventListener("click", (e) => {
        e.preventDefault();
        document.querySelector('.modalAR').classList.add("activePopup");
    });

    modalCommercial && modalCommercial.addEventListener("click", (e) => {
        e.preventDefault();
        document.querySelector('.modalCommercial').classList.add("activePopup");
    });

    btnClose && btnClose.addEventListener("click", (e) => {
        e.preventDefault();
        let modalE = btnClose.closest(".modal");
        modalE.classList.remove("activePopup");
    });
    closePopups.length > 0 && Array.from(closePopups).map((closePopup) => {
        closePopup.addEventListener("click", (e) => {
            e.preventDefault();
            let modalE = closePopup.closest(".modal")
            if(closePopup.parentElement.children[1].children[0]) {
                closePopup.parentElement.children[1].children[0].removeAttribute('src')
            }
            //iframeV = modalE.getElementsByTagName("iframe")[0].contentWindow;
            // console.log(iframeV)
            
            // iframeV.postMessage('{"event":"command","func":pauseVideo","args":""}', '*');
            modalE.classList.remove("activePopup");

        });
    });

    activeVideos.length > 0 && Array.from(activeVideos).map((activeVideo) => {
        activeVideo.addEventListener("click", (e) => {
            console.log("entro");
            e.preventDefault();
            const target = activeVideo.dataset.video,
            iframe = videoModal && videoModal.querySelector('.boxIframe__iframe');

            iframe.setAttribute('src','https://www.youtube.com/embed/'+target)
            videoModal.classList.add("activePopup");
        });
    });

    popUpsClose.map( popupActive => {
        popupActive.addEventListener('click', popupclose => {
            popupActive.closest('.modal').remove('.activePopup')
        })
    })
}


export {
    popups
}
