function home(){
    gsap.registerPlugin(ScrollTrigger);

    let getRatio = el => window.innerHeight / (window.innerHeight + el.offsetHeight);

    gsap.utils.toArray(".panelScroll").forEach((section, i) => {
        section.bg = section.querySelector(".bkGen"); 

        
        // the first image (i === 0) should be handled differently because it should start at the very top.
        // use function-based values in order to keep things responsive
        gsap.fromTo(section.bg, {
            backgroundPosition: () => i ? `50% ${-window.innerHeight * getRatio(section)}px` : "50% 0px"
        }, {
            backgroundPosition: () => `50% ${window.innerHeight * (1 - getRatio(section))}px`,
            ease: "none",
            scrollTrigger: {
                trigger: section,
                start: () => i ? "top bottom" : "top top", 
                end: "bottom top",
                scrub: 1,
                invalidateOnRefresh: true // to make it responsive
            }
        });

    });
}
export {
    home
}