function album(){

	const formNatural = document.querySelector('.formNatural'),
        inputNatural = formNatural && formNatural.querySelector('.formcodeSnack--input'),
        modalSnack = document.querySelector('.modalSnack'),
        boxSnack = document.querySelector('.boxcodesSnacks__single')

        formNatural && formNatural.addEventListener("submit", (e)=> {
        e.preventDefault()

        let code = 12345
        if(inputNatural.value.length > 5 || inputNatural.value == "" || inputNatural.value.length < 5){
            document.querySelector('.msninputSnack').classList.remove('hide')
        }else{
            document.querySelector('.msninputSnack').classList.add('hide')
            if(inputNatural.value == code){
                //modal felicitaciones
                modalSnack.classList.add('activePopup')
                const progress = document.querySelector('.boxProgress--progress'),
                 labelProgress = document.querySelector('.boxProgress--label');
                progress.value = '20'
                labelProgress.innerHTML = '20%'
            }else{
                //modal f
                modalSnack.classList.add('activePopup')
                modalSnack.querySelector('.modal__container--tit').innerHTML= '¡Lo sentimos!'
                modalSnack.querySelector('.modal__container--desc').innerHTML= 'El código que haz ingresado no es valido o ha sido ingresado previamente. Sigue  disfrutnado y desbloqueando para obtener tu bono'
                
            }
        }

    })
}
export {
    album
}