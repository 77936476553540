function ranking() {
    const listItems = document.querySelectorAll(".boxRanking__middle__single"),
        nextButton = document.querySelector(".boxRanking__middle--arrows--right"),
        prevButton = document.querySelector(".boxRanking__middle--arrows--left");

    const paginationLimit = 1;
    const pageCount = Math.ceil(listItems.length / paginationLimit);
    let currentPage = 1;

    const disableButton = (button) => {
        button && button.classList.add("disabled");
        button && button.setAttribute("disabled", true);
    };

    const enableButton = (button) => {
        button && button.classList.remove("disabled");
        button && button.removeAttribute("disabled");
    };

    const handlePageButtonsStatus = () => {
        if (currentPage === 1) {
            disableButton(prevButton);
        } else {
            enableButton(prevButton);
        }

        if (pageCount === currentPage) {
            disableButton(nextButton);
        } else {
            enableButton(nextButton);
        }
    };

    const setCurrentPage = (pageNum) => {
        currentPage = pageNum;

        // handlePageButtonsStatus();

        const prevRange = (pageNum - 1) * paginationLimit;
        const currRange = pageNum * paginationLimit;

        listItems.forEach((item, index) => {
            item.classList.add("hiddenWeek");
            if (index >= prevRange && index < currRange) {
                item.classList.remove("hiddenWeek");
            }
        });
    };

    setCurrentPage(1);

    prevButton && prevButton.addEventListener("click", () => {
        setCurrentPage(currentPage - 1);
    });

    nextButton && nextButton.addEventListener("click", () => {
        setCurrentPage(currentPage + 1);
    });

    moreData();
}

function moreData(){
    const btnAll = document.querySelectorAll('.boxPagination--more');
    btnAll.length > 0 && Array.from(btnAll).map((btn, i) => {
        const parent = btn.closest('.boxPagination'),
        elemAll = parent.querySelectorAll('.boxRanking__middle__single__ranking--body');

        btn.addEventListener("click", (e) => {
            e.preventDefault();
            btn.classList.add('disabledBtn');
            const arrayData = Array.from(elemAll).map((book, index) => {                
                if((index >= 2)){
                    book.classList.add('showS')
                }
            })
        })
        const arrayData = Array.from(elemAll).map((book, index) => {
            if(!(index >= 2)){
                book.classList.add('showS')
            }           
        })
    })
}

export { ranking }